.mainStyle{
    padding: 50px;
    width: 100%;
    background-color: #E5E5E5;
}

.flexContainer{
    display: flex;
}

.sandboxMainContainer {
    margin-top: 24px;
    margin-left: -50px;
    margin-right: -50px;
    display: flex;
    grid-template-columns: auto 1fr;
}

.sandboxNavigationPanel {
    box-shadow: '0px 0px 12px rgba(0, 0, 0, 0.1)';
    width: 80px;
    min-width: 60px;
    min-height: 100vh;
    background-color: white;
    display: grid;
    grid-template-rows: auto 20px auto auto auto auto auto auto auto 1fr;
}

.sandboxView {
    width: 100%;
    background-color: var(--main-theme-background);
}