.notificationDialog {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notificationDialogContent {
    padding: 20px;
    border-radius: 6px;
    background-color: white;
    height: 600px;
    width: 400px;
    display: grid;
    grid-template-rows: 1fr 8fr 1fr;
}

.buttonPanel {
    display: flex;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
}

.leftButton {
    margin-left: 5px;
}

.rightButtons {
    margin-left: auto;
}

.confirmTable {
    margin-left: 5px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(11 1fr);
}

.confirmTableCell {
    margin-left: 5px;
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.labelCell {
    margin-left: 5px;
    text-align: left;
}
