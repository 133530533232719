.generalTextInputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.textInput {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.generalSingleSelectContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.singleSelect {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.checkBoxes {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
}

.radioButtons {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.customRadioContainer {
    display: flex;
}

.switcher {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.image {
    grid-area: image;
    margin-left: 16px;
}

.labelFirst {
    font-family: Roboto;
    font-size: 16px;
    color: gray;
    margin-left: 10px;
}

.labelSecond {
    margin-top: 10px;
    text-align: center;
}

.labelThird {
    font-family: Roboto;
    font-size: 12px;
    color: black;
    margin: 10px 10px 0px 10px;
}
