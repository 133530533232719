.generalButtonsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.generalButtonExamples{
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 4;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.primaryButtonsContainer{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.secondaryButtonsContainer{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.labelsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.labelsExamplesContainer{
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 6;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}