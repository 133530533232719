.baseButtonStyle {
    padding: 0px;
    justify-content: flex-start;
    text-align: left;
    text-transform: none;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
}

.cutText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.gridContainer {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
}
