.largeAvatar {
    margin: 15px auto 15px auto;
    width: 56px;
    height: 56px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: inherit;
    border-radius: 50%;
    justify-content: center;
}

.nav_panel_buttons {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto auto;
    min-width: 80px;
}

.sidePanel {
    width: 80px;
    background: var(--app-main-theme-navPanelBackground);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.logoutRow {
    position: absolute;
    width: 80px;
    bottom: 0;
}
.logoutRow a {
    color: var(--main-theme-grey1);
}
