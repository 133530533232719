.tab {
    position: relative;
  }

.hovercard { 
    position: absolute; 
    z-index: 1;
    left: 150%;
    -o-transform: translateY(-22px);
    transform: translateY(-22px);
    visibility: hidden;
    opacity: 0;
    -o-transition: all .3s cubic-bezier(0.9,.1,.1,.9);
    transition: all .3s cubic-bezier(0.9,.1,.1,.9);
  }
  
.tab:hover .hovercard { 
    left: 110%;
    visibility: visible;
    opacity: 1; 
  }

.notificationIndicator {
  height: 18px;
  width: 18px;
  color: white; 
  background-color: hsl(var(--red-color-hue), 91%, 46%);
  text-align: center;
  border-radius: 10px;
  display: flex;
  justify-content:center;
  align-items: center;
  font-size: 14px;
  position: absolute;
  left: 63.75%;
  right: 13.75%;
  top: 13.39%;
  bottom: 56.25%;                   
}

.tooltiptext {
    display: flex; 
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    color: var(--main-theme-grey1);
    background-color: white;
    padding: 14px 14px; 
    border-radius: 4px; 
  }

.selectedTooltiptext {
    display: flex; 
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    color: var(--main-theme-main-color);
    background-color: var(--main-theme-lightest);
    padding: 14px 14px; 
    border-radius: 4px; 
  }