.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}
.popupInner {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 400px;
    height: 700px;
    background: #ffffff;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.headerText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}
.gridContainer {
    padding: 24px 24px 24px 24px;
    display: grid;
    grid-template-rows: 28px 20px auto 40px;
}

.buttonsGridContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto auto;
}

.firstRow {
    grid-row-start: 1;
}

.thirdRow {
    grid-row-start: 3;
}

.fourthRow {
    grid-row-start: 4;
}

.secondColumn {
    grid-column-start: 2;
}

.thirdColumn {
    grid-column-start: 3;
}
