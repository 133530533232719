.notifyTable {
    height: 100vh;
    display: grid;
    width: 100%;
    grid-template-columns: 20px 1fr 50px;
    grid-template-rows: 70px 48px 1fr 0.1fr;  
}

.selectedItems {
    display: flex;
}

.selected-items {
    display: grid;
    width: 100%;
    grid-template-columns: 10px 1fr 0.2fr;
    background-color: #B5B5B5;
    align-items: center;
    justify-items: left;
}

.selected-items p {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
}

.selected-items-selected {
    display: grid;
    width: 100%;
    grid-template-columns: 10px 1fr 0.2fr;
    background-color: #1647F6;
    align-items: center;
    justify-items: left;
}

.selected-items-selected p {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
}

.tableHeader {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    justify-items: left;
}

.tableHeader-buttons {
    display: grid;
    grid-template-rows: 10px 10px;
    align-items: center;
    justify-items: center;
}

.selected-row {
    background-color: #DDDDDD;
    box-sizing: border-box;
}

.actionButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.actionButtons div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 8px;
}

.actionButtons p {
    margin-left: 10px;
}

.open-button {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    justify-items: center;
}

.edit-button {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    justify-items: center;
}

.delete-button {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    justify-items: center;
}

.visibility {
    visibility: hidden;
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1.2px solid #4B4B4B;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
    border-color: #1647F6;
    background-color: #1647F6;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.666504 5.16699L3.99984 8.50033L11.3332 1.16699' stroke='white' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #567BFF;
}

.custom-checkbox:not(:disabled):checked + label:hover::before {
    background-color: #567BFF;
    border-color: #567BFF;
}

.custom-checkbox:not(:disabled):active + label::before {
    background-color: #567BFF;
    border-color: #567BFF;
}

.custom-checkbox:focus + label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-checkbox:focus:not(:checked) + label::before {
    border-color: #80bdff;
}

.custom-checkbox:disabled + label::before {
    background-color: #e9ecef;
}

.firstColumn {
    grid-column-start: 1;
}

.secondColumn {
    grid-column-start: 2;
}

.thirdColumn {
    grid-column-start: 3;
}

.fourthColumn {
    grid-column-start: 4;
}

.fifthColumn {
    grid-column-start: 5;
}

.firstRow {
    grid-row-start: 1;
}

.secondRow {
    grid-row-start: 2;
}

.thirdRow {
    grid-row-start: 3;
}
