.main {
    background-color: #b5b5b5;
    border-radius: 4px;
    align-items: center;
    justify-items: left;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    min-width: 1200px;
}

.main span {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding: 0;
    margin: 0 20px;
}

.active {
    background-color: #1647f6;
}

.active span {
    color: #fff;
}

.spanBtn:hover {
    cursor: pointer;
}

.buttonsFlex {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.chekedUsers {
    width: 'auto';
}
