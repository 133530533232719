.generalComponentsMainContainer {
    padding: 24px 32px 0px 32px;
}

h5 {
    margin-top: 24px;
    margin-bottom: 12px;
}

.flexContainer{
    display: flex;
}

.firstColumn{
    grid-column-start: 1;
}

.secondColumn{
    grid-column-start: 2;
}

.thirdColumn{
    grid-column-start: 3;
}

.firstRow{
    grid-row-start: 1;
}

.secondRow{
    grid-row-start: 2;
}

.thirdRow{
    grid-row-start: 3;
}

.fourthRow{
    grid-row-start: 4;
}