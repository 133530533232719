.mainContainer {
    background-color: var(--app-main-theme-background);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
    background: hsla(0, 0%, 91%, 1);
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 27%, 1);
    border-radius: 10px;
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:active {
    background: var(--main-theme-main-color);
}

progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;

    width: 317px;
    height: 4px;
}

progress[value]::-webkit-progress-bar {
    background-color: #e9e9e9;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress::-moz-progress-bar {
    background: #1647f6;
}
progress::-webkit-progress-value {
    background: #1647f6;
}
progress {
    color: #1647f6;
}
