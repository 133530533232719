.dashboardMainContainer {
    width: 100%;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    padding-bottom: 24px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        'sectionsBar featBar'
        'naming naming'
        'content content';
    overflow: auto;
}

.dashboardSectionContent {
    grid-area: content;
    margin-top: 16px;
    width: 100%;
    height: 100%;
    min-height: 500px;
}

.naming {
    grid-area: naming;
    height: 48px;
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto 1fr;
}

.namingBackButton {
    margin: auto 0px auto 11px;
}

.namingHeader {
    margin: auto 0px auto 11px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: var(--app-main-theme-text);
}

.navigationTab {
    margin: 0px 2px;
    min-width: 160px;
}

.featurePanel {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    justify-items: right;
}

.featurePanelFirstRow {
    grid-row-start: 1;
    display: grid;
    grid-template-columns: auto auto auto;
}
.featurePanelSecondRow {
    grid-row-start: 2;
    display: grid;
    grid-template-columns: auto auto auto;
}
