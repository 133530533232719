.logo {
    position: absolute;
    right: 5%;
    bottom: 5%;
}

.loginForm {
    display: inline-block;
    background-color: var(--app-main-theme-navPanelBackground);
    padding: 24px;
    width: 320px;
    height: 468px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
}

.blur {
    filter: blur(10px);
}

.centerDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.formHeader {
    height: auto;
    padding: 0%;
    color: var(--app-main-theme-text);
    font-size: 24px;
    margin: 0px;
}

.e-mail-form {
    width: 240px;
    height: 56px;
    margin-top: 40px;
    font-size: 12px;
}

.password-form {
    width: 240px;
    height: 56px;
    margin-top: 20px;
    font-size: 12px;
}

.socialLogin {
    text-align: center;
    margin-top: 20px;
    margin-right: 20px;
}

.socialLoginHeader {
    font-size: 12px;
    color: var(--app-main-theme-text);
    font-family: 'Roboto';
}

.socialNetworkButtonsContainer {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 50px 50px 1fr;
}

.facebook {
    margin-left: 20px;
    margin-right: 20px;
}

.buttons {
    padding-top: 40px;
    text-align: right;
    margin-right: 20px;
}

.buttonsContainer {
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    padding-top: 40px;
    margin-right: 20px;
    text-align: right;
}

.inputValidationErrorText {
    color: #f32828;
    font-size: 12px;
    text-align: center;
}

.alignContentCenter {
    align-content: center;
}

.firstColumn {
    grid-column-start: 1;
}

.secondColumn {
    grid-column-start: 2;
}

.thirdColumn {
    grid-column-start: 3;
}

.fourthColumn {
    grid-column-start: 4;
}

.log-in-btn {
    grid-column-start: 1;
    display: inline-block;
}

.loginAnimationDiv {
    grid-column-start: 2;
    display: inline-block;
    margin-left: 10px;
    width: 30px;
    align-self: center;
}

.reg-btn {
    grid-column-start: 3;
    display: inline-block;
}
