.selectedItems {
    display: grid;
    grid-template-columns: 16px 1fr 1fr 1fr;
    background-color: #B5B5B5;
    border-radius: 4px;
    align-items: center;
    justify-items: left;
    height: 48px;
}

.headerText{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: center;
    padding: 0; 
    margin: 0;   
}

.selectedItemsSelected{    
    display: grid;
    width: 100%;    
    grid-template-columns: 16px 100px 305px auto 405px 16px;
    background-color: #1647F6;
    border-radius: 4px;
    height: 48px; 
    align-items: center;
    justify-items: left;
}

.actionButtons {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
}

.visibility{
    visibility: hidden;
}

.firstColumn{
    grid-column-start: 1;
}

.secondColumn{
    grid-column-start: 2;
}

.thirdColumn{
    grid-column-start: 3;
}

.fourthColumn{
    grid-column-start: 4;
}

.fifthColumn{
    grid-column-start: 5;
}
