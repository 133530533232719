@keyframes two {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
.main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
.two div {
    position: absolute;
    width: 142.20000000000002px;
    height: 142.20000000000002px;
    border: 28.44px solid #1647f6;
    border-top-color: transparent;
    border-radius: 50%;
}
.two div {
    animation: two 0.78125s linear infinite;
    top: 118.5px;
    left: 118.5px;
    -webkit-animation: two 0.78125s linear infinite;
}
.one {
    width: 237px;
    height: 237px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
}
.two {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.two div {
    box-sizing: content-box;
}
