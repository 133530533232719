body {
    background-color: #f1f5fb;
    font-family: Roboto, sans-serif;
}

.logo {
    position: absolute;
    right: 5%;
    bottom: 5%;
    text-align: left;
    margin: 0;
    padding: 0;
}

.registerForm {
    display: inline-block;
    background-color: var(--app-main-theme-navPanelBackground);
    padding: 24px;
    width: 568px;
    border-radius: 8px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
}

.centerDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* Contains layout definition */
.registrationFormContainer {
    display: grid;
    grid-template-rows: 50px auto 20px auto;
}

/* Registration form header */
.formHeader {
    grid-row-start: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: var(--app-main-theme-text);
}

/* Contains all input groups layout definition */
.registrationFormInputFieldsContainer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 50px 1fr;
}

.actionButtonsContainer {
    display: grid;
    grid-template-columns: 1fr auto 15px auto;
}

.inlineBlock {
    display: inline-block;
}

.firstRow {
    grid-row-start: 1;
}

.secondRow {
    grid-row-start: 2;
}

.thirdRow {
    grid-row-start: 3;
}

.fourthRow {
    grid-row-start: 4;
}

.firstColumn {
    grid-column-start: 1;
}

.secondColumn {
    grid-column-start: 2;
}

.thirdColumn {
    grid-column-start: 3;
}

.fourthColumn {
    grid-column-start: 4;
}

.alignRight {
    align-self: right;
}

/* Specifies template for input unit (label + field + velidation message) */
.inputContainer {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.popUp {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}
