.main {
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.main:hover {
    background: #dedede;
    cursor: pointer;
}

.selectedRow {
    background: #dedede;
}

.chekbox {
    border-bottom: 1px solid #dfdfdf;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
}

.firstColumn {
    width: 40px !important;
}
