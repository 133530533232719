.notifyMainContainer {
    padding: 24px 32px 0px 32px;  
    display: grid;
    grid-template-rows: 24px auto 32px auto;
}

.navigationTab {
    display: flex;
}

.navigation {
    margin: 0px 0px 10px 0px;
    gap: 30px 20px;
    display: flex;
    flex-wrap: wrap;
}

.buttonPanel {
    height: 40px;
    margin-left: auto;
    display: flex;
}

.notifyContent {
    height: max-content;
    overflow-x: auto;    
    margin-top: 24px;    
    display: grid;
}