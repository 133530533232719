.container {
    display: flex;
    height: 40px;
    padding: 0px 8px;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.container:hover {
    background: rgba(68, 68, 68, 0.1);
}

.container:active {
    color: hsla(227, 93%, 53%, 1);
}

.icon {
    margin: auto 8px;
}

.text {
    margin: auto 8px auto 0px;
}