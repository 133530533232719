/* Panel with direction header and tooltip icon */
.filtersGroupHeaderPanel {
    display: flex;
    margin-bottom: 24px;
    margin-top: 24px;
    color: var(--app-main-theme-text);
}

#directionTooltipIcon {
    margin-left: auto;
    align-self: center;
    margin-right: 8px;
    position: relative;
    display: inline-block;
}

#directionTooltipIcon:hover {
    color: var(--main-theme-main-color);
}

/* Style for tooltip container */
#directionTooltipIcon .directionTooltipDiv {
    width: auto;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
    background-color: white;
    border-radius: 4px;
    position: absolute;
    padding: 14px 28px;

    visibility: hidden;
    opacity: 0;
    -o-transform: translate(60px, -24px);
    transform: translate(60px, -24px);
    -o-transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

/* Change '.directionTooltipDiv' style when '#directionTooltipIcon' hover */
#directionTooltipIcon:hover .directionTooltipDiv {
    opacity: 1;
    visibility: visible;
    -o-transform: translate(30px, -24px);
    transform: translate(30px, -24px);
}

.tooltipHeader {
    margin: 0 0 5px;
    font-weight: 700;
    white-space: nowrap;
    color: var(--main-theme-grey1);
    font-family: Roboto;
}

.tootipText {
    min-width: 300px;
    font-family: Roboto;
    color: var(--main-theme-grey1);
}

.moreItemsButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 8px 16px;

    background: #ffffff;

    border: 1px solid hsla(0, 0%, var(--main-theme-grey0-intensity), 0.35);
    box-sizing: border-box;

    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    cursor: pointer;
    color: hsla(0, 0%, var(--main-theme-grey4-intensity), 1);
    margin: 16px 0px;
}

.moreItemsButtonContainer:hover {
    border-color: hsla(0, 0%, var(--main-theme-grey0-intensity), 0.5);
}

.allItemsPopupView {
    padding: 24px;
    width: 500px;

    -o-transform: translate(256px, -75%);
    transform: translate(256px, -75%);

    /* Flexbox Styles */
    display: flex;
    flex-wrap: wrap;

    background: #ffffff;
    border: 1px solid hsla(0, 0%, var(--main-theme-grey0-intensity), 0.35);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.allItemsPopupView div {
    width: 45%;
}

.allProductFiltersPopupView {
    padding: 24px;
    width: 1300px;

    -o-transform: translate(256px, -75%);
    transform: translate(256px, -75%);

    /* Grid Styles */
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    background: #ffffff;
    border: 1px solid hsla(0, 0%, var(--main-theme-grey0-intensity), 0.35);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.allProductFiltersPopupView .columnHeader {
    color: hsla(0, 0%, var(--main-theme-grey1-intensity), 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 35px;
}

.filterOptionName {
    color: var(--app-main-theme-text);
}
