:root {
    --main-color-hue: var(--blue-color-hue); /* Blue in HSL as default*/

    /* Main app colors */
    --blue-color-hue: 227;
    --green-color-hue: 112;
    --yellow-color-hue: 56;
    --orange-color-hue: 29;
    --red-color-hue: 359;

    --main-theme-dark: hsl(calc(var(--main-color-hue) - 18), 90%, 20%);
    --main-theme-darker: hsl(calc(var(--main-color-hue) - 18), 90%, 35%);
    --main-theme-main-color: hsl(var(--main-color-hue), 90%, 50%);
    --main-theme-light: hsl(var(--main-color-hue), 91%, 70%);
    --main-theme-lighter: hsl(calc(var(--main-color-hue) - 18), 97%, 88%);
    --main-theme-lightest: hsl(calc(var(--main-color-hue) - 18), 93%, 94%);

    --main-theme-validation-error-color: hsl(var(--red-color-hue), 91%, 53%);

    /* Grey colors */
    --main-theme-black-intensity: 12%;
    --main-theme-grey0-intensity: 23%;
    --main-theme-grey1-intensity: 29%;
    --main-theme-grey2-intensity: 47%;
    --main-theme-grey3-intensity: 60%;
    --main-theme-grey4-intensity: 73%;
    --main-theme-grey5-intensity: 87%;

    --main-theme-backgroundGrey-intensity: 95%;

    --main-theme-black: hsl(0, 0%, var(--main-theme-black-intensity));
    --main-theme-grey0: hsl(0, 0%, var(--main-theme-grey0-intensity));
    --main-theme-grey1: hsl(0, 0%, var(--main-theme-grey1-intensity));
    --main-theme-grey2: hsl(0, 0%, var(--main-theme-grey2-intensity));
    --main-theme-grey3: hsl(0, 0%, var(--main-theme-grey3-intensity));
    --main-theme-grey4: hsl(0, 0%, var(--main-theme-grey4-intensity));
    --main-theme-grey5: hsl(0, 0%, var(--main-theme-grey5-intensity));
    --main-theme-background: hsl(
        0,
        0%,
        var(--main-theme-backgroundGrey-intensity)
    );

    --app-main-theme-background: hsl(0, 0%, 90%);
    --app-main-theme-text: hsl(0, 0%, 27%);
    --app-main-theme-navPanelBackground: hsl(0, 0%, 100%);
}
