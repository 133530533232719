.main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 20% 80%;
    grid-template-columns: 50% 50%;
}
.buttons {
    grid-row-start: 1;
    grid-column-start: 1;
}
.charts {
    grid-row-start: 2;
    grid-column-start: 2;
    display: grid;
    grid-template-rows: 30% 30% 30%;
    grid-template-columns: 30% 30% 30%;
}
.container {
    grid-row-start: 2;
    grid-column-start: 1;
    position: relative;
}

.generatorWidget {
    margin: 10px;
}

.invertorWidget {
    margin: 10px;
}
