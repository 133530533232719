.optionsPanel {
    float: left;
    position: absolute;
    background: var(--app-main-theme-navPanelBackground);
    z-index: 4;
    top: 50%;
    right: 100%;
    border-radius: 4px;
    padding: 4px 0px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    color: hsla(0, 0%, 27%, 1);
}

.widgetName {
    justify-content: center;
    align-items: center;
    margin: auto 8px;
    font-size: 14px;
    line-height: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
}
