.switcherContainer {
    display: grid;
    grid-template-columns: 32px auto;
    grid-template-rows: auto;
}
.customSwitcher + label {
    display: none;
}
.customSwitcher {
    -webkit-appearance: none;
}
.customSwitcher {
    position: relative;
    display: inline-block;
    align-self: center;

    width: 32px;
    height: 18px;
    margin: 0;

    background:transparent;
    border: 1px solid var(--main-theme-grey1);
    border-radius: 9px;

    transition: 0.3s;
}
.customSwitcher::after {
    content: "";

    display: inline-block;
    position: absolute;
    left: 3px;
    top: 2px;

    width: 12px;
    height: 12px;
    background-color: var(--main-theme-grey1);
    border-radius: 50%;

    transform: translateX(0);
    transition: 0.3s;
}
.customSwitcher:checked::after {
    transform: translateX(calc(100%));
    background-color: #fff;  
}
.customSwitcher:checked {
    border: 1px solid var(--main-theme-main-color);
    background-color: var(--main-theme-main-color);
}
.customSwitcher:hover::after {
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 2px;

    width: 12px;
    height: 12px;
    background-color: var(--main-theme-grey2);
    border-radius: 50%;
}
.customSwitcher:hover {
    background-color: transparent;
    border: 1px solid var(--main-theme-grey2);
}
.customSwitcher:checked:hover {
    border: 1px solid var(--main-theme-light);
    background-color: var(--main-theme-light);
} 
.customSwitcher:checked:hover::after {
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 2px;

    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
} 
.customSwitcher:disabled {
    border-color: var(--main-theme-grey3);
    background: var(--main-theme-grey5);
    pointer-events: none;
}
.spanText {
    display: grid;
    align-self : center;
}