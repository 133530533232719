.widget {
    height: 100%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'header header header'
        'content content content';
}

.widgetHeader {
    margin: 10px;
    grid-area: header;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr auto;
    color: var(--app-main-theme-text);
}

.svgContainer {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widgetContent {
    position: relative;
    grid-area: content;
    width: 100%;
    height: 100%;
    justify-content: stretch;
    align-content: stretch;
}

.chartContainer {
    position: absolute;
    height: 100%;
    width: 100%;
}

.featButton {
    position: relative;
}

.loading {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
