.radioContainer {
    display: grid;
    grid-template-columns: 17px auto;
    grid-template-rows: auto;
}
.customRadioButton {
    position: absolute;
    opacity: 0;
    z-index: -1;
    align-self : center;
}
.customRadioButton + label {
    display: grid;
    height: 16.5px;
    width: 16.5px;
    border: 1px solid var(--main-theme-grey1);
    border-radius: 100%;
    align-self : center; 
}

.customRadioButton:checked + label {
    display: grid;
    height: 16.5px;
    width: 16.5px;
    border-radius: 100%; 
    border-color: var(--main-theme-main-color);
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M4%208C6.20914%208%208%206.20914%208%204C8%201.79086%206.20914%200%204%200C1.79086%200%200%201.79086%200%204C0%206.20914%201.79086%208%204%208Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-color: var(--main-theme-main-color);
    background-position: center;
    align-self : center;
    background-size: 8px;
}

.customRadioButton:hover + label {
    border: 1px solid var(--main-theme-grey2);
}

.customRadioButton:checked:hover + label{
    background-color: var(--main-theme-light);
    border-color: var(--main-theme-light);
}

.customRadioButton:disabled + label {
    border-color: var(--main-theme-grey3);
    background: var(--main-theme-grey5);
} 
.spanText {
    align-self : center;
}