.container {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto;
}

.customCheckbox {
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: -1;
  align-self : center;
}
.customCheckbox + label {
  display: grid;
  height: 18px;
  width: 18px;
  border: 1.2px solid var(--main-theme-grey1);
  border-radius: 1px;
  align-self : center; 
}

.customCheckbox:checked + label {
  display: grid;
  align-self : center;
  height: 18px;
  width: 18px;
  border-radius: 1px; 
  border-color: var(--main-theme-main-color);
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M0.666504%204.66699L3.99984%208.00033L11.3332%200.666992%22%20stroke%3D%22white%22%20stroke-width%3D%221.2%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-color: var(--main-theme-main-color);
  background-position: center center;
  background-size: 75% 65%;
}

.customCheckbox:hover + label {
  border: 1.2px solid var(--main-theme-grey2);
}

.customCheckbox:checked:hover + label{
  background-color: var(--main-theme-light);
  border-color: var(--main-theme-light);
}

.customCheckbox:disabled + label {
  border-color: var(--main-theme-grey3);
  background: var(--main-theme-grey5);
}

.customIndetermineCheckbox {
  position: absolute;
  opacity: 0;
  z-index: -1;
  align-self : center;
}

.customIndetermineCheckbox + label {
  display: grid;
  height: 18px;
  width: 18px;
  border: 1.2px solid var(--main-theme-grey1);
  border-radius: 1px; 
  align-self : center;
}

.customIndetermineCheckbox:checked + label {
  display: grid;
  height: 18px;
  width: 18px;
  border-radius: 1px; 
  border-color: var(--main-theme-main-color);
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%222%22%20viewBox%3D%220%200%2010%202%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9%201H1%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-color: var(--main-theme-main-color);
  background-position: center center;
  background-size: 75% 65%;
}

.customIndetermineCheckbox:hover + label {
  border: 1.2px solid var(--main-theme-grey2);
}

.customIndetermineCheckbox:checked:hover + label{
  background-color: var(--main-theme-light);
  border-color: var(--main-theme-light);
}

.customIndetermineCheckbox:disabled + label {
  border-color: var(--main-theme-grey3);
  background: var(--main-theme-grey5);
}

.spanText {
  margin-left: 12px;
  align-self : center; 
}
