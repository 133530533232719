.table__td {
    font-size: 16px;
    line-height: 19px;
    color: #444444;
    width: 20%;
    padding: 0px 8px 0px 16px;
    height: 48px;
    min-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.noOVerflow {
    overflow: visible !important;
}
