.main {
    width: 95%;
    margin: 20px auto;
}
.blur {
    filter: blur(10px);
}
.tabs {
    margin: 20px 0;
    display: flex;
    width: 410px;
    justify-content: space-between;
}

/* table {
    
    table-layout: fixed;
}

td,
th {
    width: 100%;
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
} */
