.navigationTab {
    position: relative;
    display: inline-block;
    text-align: center;
    color: var(--main-theme-grey1);
    transition: '.3s';
}

.navigationTabSelected {
    position: relative;
    display: inline-block;
    text-align: center;
    color: var(--main-theme-main-color);
    transition: '.3s';
}

.navigationTab:hover {
    color: var(--main-theme-grey2);
    transition: '.3s',
}

.content {
    padding-left: 8px;
    padding-right: 8px;
    display:inline-flex;
    margin: 0 auto;
    font-size: 16px;
    margin-bottom: 8px;
    cursor: default;
}

.text {
    margin-left: 4px;
}

.bottomLine {
    position: absolute;
    bottom: 0;

    height: 2px;
    width: 100%;

    background: hsla(0, 0%, var(--main-theme-grey0-intensity), 0.35);
    border-radius: 2px 2px 0px 0px;
}

.bottomLineSelected {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 2px;

    background: var(--main-theme-main-color);
    border-radius: 2px 2px 0px 0px;
    margin-top: auto;
}

.navigationTab:hover .bottomLine { 
    background-Color: var(--main-theme-grey2);
}