.filtersPanelHidden {
    width: 240px;
    opacity: 0;
    -o-transition: all 0.1s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.1s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

.filtersPanelVisible {
    width: 240px;
    margin-bottom: 20px;
    opacity: 1;
    -o-transition: all 0.5s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.5s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

.filtersHeader {
    margin-top: -10px;
    font-weight: 500;
    font-size: 32px;
    color: var(--app-main-theme-text);
    font-family: Roboto;
}

.dynamicSearchPanel {
    margin-top: 24px;
    display: flex;
    margin-bottom: 24px;
}

.descriptionText {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    color: var(--app-main-theme-text);
    font-family: Roboto;
}
