.generalTextInputContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.textInputExamples{
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 5;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.generalSingleSelectContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.singleSelectExamples{
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 4;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}
.checkBoxesExamples {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
}
.radioButtonsExamples {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.customRadioContainer {
    display: flex;
}

.switcherExamples {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.statusExamples {
    display: flex;
    border-radius: 10px;
    border: 1px dashed #7B61FF;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.statusExample{
    display: flex;
}