.mainContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
}
.filtersForm {
    grid-column-start: 1;
}

.PDFViewer {
    grid-column-start: 2;
}
