.sideBarTabsExamples{
    background-color: white;
    border-radius: 10px;
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 4;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.navigationTabsExamples{
    padding: 20px;
    border-radius: 10px; 
    border: 1px dashed #7B61FF;
    grid-column-start: 1;
    grid-column-end: 4;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr auto;
}