.mainContainer {
    position: relative;
    height: 90%;
    width: 100%;
    overflow: auto;
    display: grid;
    grid-template-rows: 90%;
}
.logTable {
    margin: 0px 50px 0px 50px;
    text-align: center;
}
.timeHeader {
    width: 50%;
}
.errorHeader {
    width: 50%;
}
