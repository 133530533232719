.table__th {
    padding: 0px 8px 0px 16px;
    font-size: 14px;
    line-height: 19px;
    height: 48px;
    color: #444444;
    border-bottom: 1px solid #dfdfdf;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
    width: 20%;
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.wrapperTh {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sortButtons {
    display: flex;
    flex-direction: column;
}
