.hslWheelImage{
    margin-top: 24px;
    height: 250px;
    width: 250px;
}

.coloredBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #B5B5B5;
    margin: 10px;
    height: 100px;
    width: 100px;
    font-size: 14px;
    border-radius: 8px;
}