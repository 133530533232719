.selectControl {
    display: grid;
    grid-template-columns: auto 1fr auto;

    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;

    background: var(--app-main-theme-navPanelBackground);
}

/* Style for disabled component */
.selectControlDisabled {
    background-color: var(--main-theme-grey5);
    color: var(--main-theme-grey3);
}

/* Style for simple select component */
.selectControl.general {
    border: 1px solid hsla(0, 0%, var(--main-theme-grey0-intensity), 0.35);
}

.selectControl.general:hover {
    border: 0.5px solid hsla(0, 0%, var(--main-theme-grey0-intensity), 0.5);
    background-color: hsla(0, 0%, var(--main-theme-grey1-intensity), 0.1);
}

/* Style for select component with label */
.selectControl.label {
    border: 1px solid hsla(0, 0%, var(--main-theme-grey3-intensity), 1);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.selectControl.label:hover {
    border-color: hsla(0, 0%, var(--main-theme-grey0-intensity), 0.5);
    background-color: hsla(0, 0%, var(--main-theme-grey1-intensity), 0.1);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
}

/* Style for focused element */
.selectControl.menuOpened {
    border: 1px solid var(--main-theme-main-color);
    color: var(--main-theme-main-color);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.selectControl.validationError {
    border: 1px solid var(--main-theme-validation-error-color);
}

/* Style for label text (header) */
.selectControllabelText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 6px;
    color: var(--main-theme-grey1);
    cursor: default;

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.selectControl .placeHolderText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-left: 16px;
    margin-bottom: 0px;
    color: var(--main-theme-grey4);

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.selectControl .selectedItemText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-left: 16px;
    color: var(--app-main-theme-text);

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

/* Arrow icon style */
.selectControl .arrowIcon {
    width: 24px;
    margin-left: auto;
    margin-top: -3px;
    margin-right: 8px;

    -o-transition: transform 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: transform 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

/* Popup meny styles */
.popUpMenu {
    margin-top: 8px;
    z-index: 1;

    padding-right: 4px;

    opacity: 1;
    min-height: 50px;
    width: 100%;

    background: #ffffff;
    border: 1px solid hsla(0, 0%, var(--main-theme-grey0-intensity), 0.35);
    border-radius: 4px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);

    transition: opacity 0.2s;
}

/* Scrollbar */
div::-webkit-scrollbar {
    width: 4px;
}

/* Pop up menu item style */
.popUpMenu div {
    display: block;
    align-items: center;
    padding: 10px 16px;

    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    color: var(--main-theme-grey1);

    /* Cut text with dots when is too long */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.popUpMenu div:hover {
    background: hsla(0, 0%, var(--main-theme-grey1-intensity), 0.1);
}
