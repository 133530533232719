.iconButton {
    color: white;
    width: 40px;
    height: 40px;
    background-color: var(--main-theme-main-color);
    border-radius: 20px;
    border: 1px solid transparent;
}

.iconButton:hover {
    background-color: var(--main-theme-light);
}

.iconButton:active {
    background-color: var(--main-theme-lightest);
    color: var(--main-theme-main-color);
    border-color: var(--main-theme-light);
}

.facbookIcon {
    width: 45px;
    height: 45px;
}

.facbookIcon:hover {
    opacity: .8;
}

.FBLoginComponent {
    margin-top: -4px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
}