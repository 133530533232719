.divBase {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 8px 7px 16px;
    background: #ffffff;
    border: 1px solid rgba(43, 43, 43, 0.35);
    box-sizing: border-box;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.inputBase {
    border: 0;
    outline: none;
    background-color: transparent;
    display: table-cell;
    width: 100%;
    caret-color: var(--main-theme-main-color);
}

.inputBase::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16L12 20L16 16' fill='%23848484'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 8L12 4L8 8' fill='%23848484'/%3E%3C/svg%3E");
}

.focusedDiv {
    border: 1px solid #1647f6;
    box-sizing: border-box;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.divBase:hover {
    background: #dedede;
    border: 1px solid hsla(0, 0%, var(--main-theme-grey3-intensity), 1);
}

.focusedDiv:hover {
    background: #ffffff;
    border: 1px solid #1647f6;
    box-sizing: border-box;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}
