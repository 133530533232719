.changeNameInputPanel {
    z-index: 1;
    padding: 4px;
    
    background-color: white;
    border-radius: 4px;

    display: grid;
    grid-template-columns: 1fr auto auto;

    width: 100%;
    min-width: 100px;
}

.changeNameInputPanel input {
    outline: none;
    grid-column-start: 1;

    width: 100%;
    min-width: 50px;
    
    border: 1px solid var(--main-theme-light);
    padding: 0px 4px;

    color: var(--main-theme-grey1);
    caret-color: var(--main-theme-main-color);
}

.changeNameInputPanel input:focus {
    box-shadow: none;
    background-color: var(--main-theme-lightest);
    border: 1px solid var(--main-theme-light);
    caret-color: var(--main-theme-main-color);
}