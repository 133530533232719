.container {
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
}

.content {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    line-height: 37,5px;
}

.caption {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 21,09px;
    margin: 7px;
    color: hsla(0, 0%, 47%, 1);
}