.mainContainer {
    margin: 20px;
    display: grid;
    grid-template-columns: 20% auto 20%;
    grid-template-rows: min-content;
}

.userSettingsHeader {
    grid-column-start: 2;
    max-height: 100px;
}

.userSettingsHeader span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    color: var(--app-main-theme-text);
}

.themeSelector {
    margin: 20px;
    display: grid;
    grid-template-columns: 20% 20%;
}

.userSettings {
    height: 300px;
    grid-column-start: 2;
    background-color: var(--app-main-theme-navPanelBackground);
    color: var(--app-main-theme-text);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
        0px 1px 2px rgb(100 116 139 / 10%);
}

.userSettingsLanguages {
    margin: 20px;
    display: grid;
    grid-template-columns: 20% 20%;
}
.userSettingsTimezone {
    margin: 20px;
    display: grid;
    grid-template-columns: 20% 20%;
}

.userSettingsLanguages span {
    text-align: left;
    align-self: center;
    margin-right: 20px;
}

.changePasswordContainer {
    margin: 20px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
}

.changePasswordContainer span {
    text-align: left;
    align-self: center;
    margin-right: 20px;
}
