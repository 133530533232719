.featurePanel {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.featureContainer {
    background: white;
    padding: 0px;
    border: 1px solid rgba(43, 43, 43, 0.35);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frequencyContainer{
    margin-left: 8px;
}

.item {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #444444;
}

.fixItem {
    width: 40px;
}

.optionsPanel {
    float: left;
    position: absolute;
    background: hsl(0, 0%, 100%);
    z-Index: 100;
    top: 100%;
    right: 0%;
    margin-top: 9px;
    border-Radius: 4px;
    padding: 4px 0px;
    box-Shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    color: hsla(0, 0%, 27%, 1);
}

.featButton {
    position: relative;
}