tbody .tableRow:hover{
    background: #eaeaea;
}

tbody .tableRow:focus{
    background-color:#E1EDFF;
}

.tdLenght{
    width: 15%;
    padding: 0px 8px 0px 16px;
    height: 48px;
}
 .tableRow{
    height: 48px;
}

.selectedRow{
    background-color: #DDDDDD;
    box-sizing: border-box;
}

.firstColumn{
    grid-column-start: 1;
}

.secondColumn{
    grid-column-start: 2;
}

.cutLongText{
    max-width: 200px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}