.mainContainer {
    width: 100%;
    height: 100%;
    margin: 10px;
    display: grid;
    grid-template-rows: 14% 14% 14% 14% 14% 14% 14%;
}

.row {
    display: grid;
    grid-template-columns: 50% 50%;
}

.bold {
    font-weight: bold;
}
