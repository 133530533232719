.mainContainer {
    text-align: center;
    display: grid;
    grid-template-columns: auto auto;
}

.timeStart {
    display: grid;
    grid-template-columns: auto auto auto;
}

.timeEnd {
    display: grid;
    grid-template-columns: auto auto auto;
}

.timeInput {
    margin-right: 8px;
    margin-left: 8px;
}

.text {
    text-align: center;
    margin: auto 8px auto 0px;
}
