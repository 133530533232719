.devicesMainContainer {
    height: 100%;
    padding: 24px 32px 0px 32px;
    overflow: auto;
}

.blur {
    filter: blur(10px);
}

.flexContainer {
    display: flex;
}

.editButtonAndSwitcherContainer {
    display: flex;
    margin-left: auto;
}

.viewTypeSwitchContainer {
    height: 40px;
    background-color: var(--app-main-theme-navPanelBackground);
    margin-left: auto;
    border: 1px solid rgba(43, 43, 43, 0.35);
    border-radius: 4px;
    display: flex;
}

.filterLabelsContainer {
    margin-top: -5px;
    display: flex;
    flex-wrap: wrap;
}

.editButtonContainer {
    min-width: 70px;
}

.displayedDevices {
    margin-top: 24px;
    padding: 0%;
    display: grid;
    grid-template-columns: auto 1fr;
    /* overflow: auto;  */
}

.filtersContainerHidden {
    visibility: hidden;
    width: 0px;
    -o-transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

.filtersContainerVisible {
    margin-right: 16px;
    visibility: visible;
    width: 320px;
    -o-transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
    transition: all 0.2s cubic-bezier(0.9, 0.1, 0.1, 0.9);
}

.filterResult {
    display: grid;
    grid-template-columns: 1fr auto;
    bottom: 10px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    height: 40px;
    width: 240px;
    background: #ffffff;
    border: 1px solid rgba(43, 43, 43, 0.35);
    box-sizing: border-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.filterResultText {
    margin: 11px 0px 10px 16px;
    margin-bottom: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
}

.filterResultFiltered {
    background: #e8f5ff;
    border: 1px solid rgba(43, 43, 43, 0.35);
    box-sizing: border-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}
