.inputBase {
    border: 0;
    outline: none;
    background-color: transparent;
    display: table-cell;

    caret-color: var(--main-theme-main-color);
}

.general {
    caret-color: var(--main-theme-main-color);
}

.validationError {
    color: var(--main-theme-validation-error-color);
    caret-color: var(--main-theme-validation-error-color);
}

.inputBase::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--main-theme-grey4);
    opacity: 1; /* Firefox */
}

.inputBase:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--main-theme-grey4);
    opacity: 0.3;
}

.inputBase::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--main-theme-grey4);
    opacity: 0.3;
}

.textInputBase::-webkit-calendar-picker-indicator {
    opacity: 0;
}
