.tableColumnHeader{      
    display: grid;
    width: 100%;
    /* height: 48px; */
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: left;       
}

.tableHeader{
    border-bottom: 1px solid #dfdfdf;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);  
}

.headerText{
    color: var(--main-theme-grey1);
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin: 0px 16px;
}

.tableHeaderSortingButtons{
    display: grid;
    grid-template-rows: 14px 14px;
    align-items: center;
    justify-items: center;   
    
    margin-right: 10px;
}

.secondColumn{
    grid-column-start: 2;
}
