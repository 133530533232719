.labelContainer {
    display: grid;
    grid-template-columns: 1fr auto;

    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 8px;
    height: 40px;
    margin: 5px 8px;

    border-radius: 20px;
}

.labelContainer p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    margin-right: 8px;
    margin-bottom: 0px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.primaryLabel {
    background-color: var(--main-theme-main-color);
    color: white;
}

.content p {
    margin-bottom: 0;
}

.secondaryLabel {
    background-color: var(--main-theme-lighter);
    color: var(--main-theme-main-color);
}

.successLabel {
    background-color: hsla(112, 100%, 89%, 1);
    color: hsla(112, 100%, 29%, 1);
}

.warningLabel {
    background-color: hsla(30, 100%, 93%, 1);
    color: hsla(29, 91%, 53%, 1);
}

.dangerLabel {
    background-color: hsla(var(--red-color-hue), 91%, 88%, 1);
    color: hsla(var(--red-color-hue), 91%, 53%, 1);
}
