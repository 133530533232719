.mainContainer {
    width: 100%;
    height: 100%;
    margin: 10px;
    display: grid;
    grid-template-rows: 12% 12% 12% 12% 12% 12% 12% 12%;
}

.row {
    display: grid;
    grid-template-columns: 40% 60%;
}

.bold {
    font-weight: bold;
}
